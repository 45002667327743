import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//引入自定义插件对象:注册整个项目全局组件
import gloalComponent from '@/components'
//引入汉化语言包
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
//引入仓库
import pinia from './store'
import plugins from './plugins/index'
const app = createApp(App)
//安装使用自定义全局插件
app.use(gloalComponent)
app.use(router)
app.use(plugins)
app.use(ElementPlus, { locale: zhCn })
app.use(pinia)
// 定义全局变量
app.config.globalProperties.$Num = 1 // 定义全局变量 数值类型
app.config.globalProperties.$Switch = 2 // 定义全局变量 开关类型
app.config.globalProperties.$Warn = 3 // 定义全局变量 报警类型
app.config.globalProperties.$Error = 4 // 定义全局变量 故障类型
app.config.globalProperties.$Yes = 1 // 定义全局变量 是
app.config.globalProperties.$No = 0 // 定义全局变量 否
app.mount('#app')
