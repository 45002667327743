import { post } from './index'
const baseUrl = '/park-zigong/web/'
export function AccountCenter(path: string, params?: object) {
    interface paramType {
        [propName: string]: string
    }
    const param: paramType = {
        // 添加用户
        userAdd: 'park-user/add',
        // 用户列表
        userList: 'park-user/list',
        // 用户详情
        userDetail: 'park-user/detail',
        // 用户更新
        userUpdate: 'park-user/update',
        // 账户详情
        accountDetail: 'account/detail',
        // 首页用户信息
        indexUserInfo: 'index/user-info',
        // 编辑账户
        accountUpdate: 'account/update',
        // 编辑邮箱
        accountUpdateEmail: 'account/update-email',
        // 编辑密码
        accountUpdatePassword: 'account/update-password',
        // 编辑手机
        accountUpdatePhone: 'account/update-phone',
        // 发送验证码
        accountUpdatePhoneSms: 'account/update-phone-sms',
    }
    const url = baseUrl + param[path]
    return post(url, params)
}
