<template>
    <div style="width: 100%; height: 52px">
        <div class="header-nav">
            <div class="title-nav" @click="goHome">
                <div class="title">自贡高新技术产业开发区</div>
                <div class="desc">VOCs治理设施运维监管平台</div>
            </div>
            <div class="user-box">
                <img src="@/assets/images/user.png" class="user" alt="" />
                <el-dropdown trigger="click" @command="handleCommand" popper-class="route-dropdown">
                    <div class="user-name overflow">{{ userData.name }}</div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item :class="{ active: route.fullPath === '/accountCenter' }" command="/accountCenter">账户中心</el-dropdown-item>
                            <el-dropdown-item command="/login">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div class="lineLeft1"></div>
            <div class="lineLeft" :style="{ width: screenX - 188 + 'px', left: '187px' }"></div>
            <div class="lineRight1"></div>
            <div class="lineRight" :style="{ width: screenX - 188 + 'px' }"></div>
            <img src="@/assets/images/header1.png" style="width: 35px; height: 7px; position: absolute; left: 125px; top: 20px" alt="" />
            <img src="@/assets/images/header2.png" style="width: 35px; height: 7px; position: absolute; right: 123px; top: 22.5px" alt="" />
            <img src="@/assets/images/header3.png" style="width: 696px; height: 55px; position: absolute; top: 15.5px; left: 50%; transform: translateX(-50%)" alt="" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { AccountCenter } from '@/api/AccountCenter'
import $bus from '@/bus'
import { onMounted, onUnmounted, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const router = useRouter()
const route = useRoute()
let screenX = ref(0)
const userData = reactive({
    comapny: '',
    name: '',
})
onMounted(() => {
    $bus.on('upUserInfo', () => {
        getUserInfo()
    })
    getUserInfo()
    getWidth()
    window.addEventListener('resize', getWidth)
})
onUnmounted(() => {
    window.removeEventListener('resize', getWidth)
})

const getWidth = () => {
    screenX.value = (window.innerWidth - 695) / 2
}

const getUserInfo = () => {
    AccountCenter('indexUserInfo').then(res => {
        Object.assign(userData, res.data)
        localStorage.setItem('company', res.data.comapny)
        localStorage.setItem('name', res.data.name)
    })
}

const goHome = () => {
    router.push({
        path: '/monitoringCenter/center',
    })
}

const handleCommand = (command: string) => {
    if (command === '/login') {
        localStorage.removeItem('token')
    }
    router.push({
        path: command,
    })
}
</script>

<style scoped lang="scss">
.header-nav {
    position: fixed;
    z-index: 2001;
    width: 100%;
    height: 52px;
    background-size: 100% 100%;
    background-color: #fff;
    border-bottom: 1px solid var(--2, #00000029);
    .title-nav {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        .title {
            cursor: pointer;
            color: #000000cc;
            font-feature-settings: 'rclt' off;
            font-family: 'Segoe UI Variable';
            font-size: 20px;
            font-style: normal;
            font-weight: Bold;
            line-height: 28px;
        }

        .desc {
            cursor: pointer;
            margin-top: 2px;
            color: #0000005c;
            text-align: right;
            font-family: 'Segoe UI Variable';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .lineLeft {
        height: 2px;
        background: linear-gradient(to left, rgba(104, 163, 205, 1), rgba(104, 163, 205, 0.2));
        position: absolute;
        top: 21px;
    }

    .lineLeft1 {
        width: 73px;
        height: 2px;
        background-color: #dee9f2;
        position: absolute;
        top: 21px;
        left: 16px;
    }

    .lineRight {
        height: 2px;
        background: linear-gradient(to right, rgba(104, 163, 205, 1), rgba(104, 163, 205, 0.2));
        position: absolute;
        top: 21px;
        right: 188px;
    }
    .user-box {
        position: absolute;
        right: 8px;
        top: 18px;
        width: 100px;
        .user {
            width: 16px;
            height: 16px;
        }

        .user-name {
            max-width: 80px;
            cursor: pointer;
            margin-left: 4px;
            color: #000000d9;
            font-feature-settings: 'rclt' off;
            font-family: 'Segoe UI Variable';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }
}
</style>
<style lang="scss">
.el-dropdown-menu {
    padding: 6px;
    box-sizing: border-box;
    .el-dropdown-menu__item {
        padding: 6px 11px 8px;
        border-radius: 3px;
        width: 82px;
        height: 34px;
        box-sizing: border-box;
    }
    .active {
        position: relative;
        border-radius: 3px;
        border: 1px solid var(--Light-Fill-Color-Subtle-Secondary, #0000000a);
        background: var(--Light-Fill-Color-Subtle-Secondary, #0000000a);
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            width: 3px;
            height: 16px;
            border-radius: 999px;
            background-color: #005fb8;
            transform: translateY(-50%);
        }
    }
}
</style>
