<template>
    <header-nav v-if="showHeader"></header-nav>
    <div class="router-view" :class="{ 'router-view2': !showHeader }">
        <router-view></router-view>
    </div>
</template>
<script lang="ts" setup>
import HeaderNav from '@/components/HearderNav.vue'
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const showHeader = ref(false)
const height = ref('height:100%')
onMounted(() => {
    // 禁止双指放大
    document.documentElement.addEventListener(
        'touchstart',
        function (event) {
            if (event.touches.length > 1) {
                event.preventDefault()
            }
        },
        false
    )
    // 禁止双击放大
    let lastTouchEnd = 0
    document.documentElement.addEventListener(
        'touchend',
        function (event) {
            const now = Date.now()
            if (now - lastTouchEnd <= 300) {
                event.preventDefault()
            }
            lastTouchEnd = now
        },
        false
    )
})
watch(
    () => router.currentRoute.value.fullPath,
    newValue => {
        if (newValue.indexOf('/codeDetails') === -1 && newValue.indexOf('/devicesDetails') === -1 && newValue.indexOf('/login') === -1) {
            showHeader.value = true
            height.value = 'calc(100% - 52px)'
        } else {
            showHeader.value = false
            height.value = 'height:100%'
        }
    }
)
const debounce = (callback: any, delay: number) => {
    let tid: any
    return function () {
        const ctx = self
        tid && clearTimeout(tid)
        tid = setTimeout(() => {
            callback.apply(ctx, arguments)
        }, delay)
    }
}

const _ = (window as any).ResizeObserver
;(window as any).ResizeObserver = class ResizeObserver extends _ {
    constructor(callback: any) {
        callback = debounce(callback, 20)
        super(callback)
    }
}
</script>

<style lang="scss">
@import '@/style/common.scss';
@import '@/style/element-variables.scss';

//  body {
//   background-color: #f1f1f1;
//   width: 100% !important; //element弹窗的自带样式会重写  所以要加上
// }
#app {
    width: 100%;
    height: 100%;
    // min-width: 1280px;
    background-color: #eee;

    .router-view {
        width: 100%;
        // min-width: 1280px;
        height: 100%;
        overflow: hidden;
        // border-top: 16px solid #eee;
        //  border-bottom: 30px solid #eee;
        // padding: 16px 0 30px;
        box-sizing: border-box;
    }

    .router-view2 {
        width: 100%;
        overflow: hidden;
        min-height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
</style>
