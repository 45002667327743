import { AccountCenter } from '@/api/AccountCenter'
import { defineStore } from 'pinia'
export const useUserinfoStore = defineStore('userInfo', {
    state() {
        return {
            userInfo: { type: 0, phone: '', email: '' },
            tokenExpiredNotified: false, // 标记是否已经提示过 token 失效
        }
    },
    actions: {
        async getUserinfo() {
            await AccountCenter('accountDetail').then(res => {
                this.userInfo = res.data || {}
            })
        },
        setTokenExpiredNotified(value: any) {
            this.tokenExpiredNotified = value
        },
    },
})
