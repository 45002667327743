<template>
    <div class="my-tabs">
        <div class="item" :class="{ active: activeIndex === index }" @click="toggleValue(item, index)" v-for="(item, index) in props.dataArr" :key="index">
            {{ item }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { Ref, ref, watch } from 'vue'
const emit = defineEmits(['update:modelValue', 'change'])
let activeIndex: Ref<number> = ref(0)
const props = defineProps({
    modelValue: {
        type: Number,
        default: 0,
    },
    dataArr: {
        required: true,
        type: Array as any,
        default: () => [],
    },
})

watch(
    () => props.modelValue,
    newValue => {
        activeIndex.value = newValue
    },
    {
        immediate: true,
    }
)

const toggleValue = (item: string, i: number) => {
    activeIndex.value = i
    emit('update:modelValue', activeIndex.value) // 触发名为 'update:modelValue' 的事件
    emit('change', item, activeIndex.value)
}
</script>

<style lang="scss" scoped>
.my-tabs {
    display: flex;
    align-items: center;
    div {
        box-sizing: border-box;
    }
    .item {
        height: 32px;
        line-height: 30px;
        padding: 0 11px;
        border: 1px solid #0000000f;
        background: #f3f3f3;
        border-radius: 32px;
        color: #000000e6;
        text-align: center;
        font-feature-settings: 'rclt' off;
        font-family: 'Segoe UI Variable';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        cursor: pointer;
        margin-right: 10px;
    }
    .active {
        color: #ffffff;
        background-color: #005fb8;
    }
}
</style>
