import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: 'login',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login/index.vue'),
    },
    {
        path: '/monitoringCenter',
        name: 'monitoringCenter',
        component: () => import('@/views/MonitoringCenter/index.vue'),
        children: [
            {
                path: '',
                name: 'monitoringCenter',
                redirect: '/monitoringCenter/center',
            },
            {
                path: 'center',
                name: 'center',
                component: () => import('@/views/MonitoringCenter/MapArea/MapArea.vue'),
            },
            {
                path: 'details',
                name: 'details',
                component: () => import('@/views/MonitoringCenter/Details/Details.vue'),
            },
        ],
    },
    {
        path: '/codeDetails',
        name: 'codeDetails',
        component: () => import('@/views/EnvironmentalProtectionCodeDetails/index.vue'),
        meta: {
            name: '环保二维码',
        },
    },
    {
        path: '/devicesDetails',
        name: 'devicesDetails',
        component: () => import('@/views/EnvironmentalProtectionCodeDetails/DeviceDetails.vue'),
        meta: {
            name: '设备详情码',
        },
    },
    {
        path: '/accountCenter',
        name: 'accountCenter',
        component: () => import('@/views/AccountCenter/index.vue'),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

/* 第二步：在路由守卫router.beforeEach中设置如下代码 */
router.beforeEach((to: any) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
})
export default router
