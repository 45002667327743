<template>
    <div class="flex-baseline-between title-box" :style="{ width: width }">
        <div class="flex">
            <!-- <span :class="['iconfont', 'icon', props.iconName]"></span> -->
            <div :data-attr="props.iconName" class="title-nav">{{ props.title }}</div>
        </div>
        <div v-if="props.intro" class="intro" @click="openIntro">简介</div>
    </div>
    <div class="flex-baseline">
        <div class="line"></div>
        <div class="line2"></div>
    </div>
</template>
<script setup lang="ts">
const emit = defineEmits(['openIntro'])
const props = defineProps({
    iconName: {
        type: String,
        default: '\x624',
    },
    width: {
        type: String,
        default: '327px',
    },
    title: {
        type: String,
        default: '',
    },
    intro: {
        type: Boolean,
        default: false,
    },
})

const openIntro = () => {
    emit('openIntro')
}
</script>
<style lang="scss" scoped>
.flex {
    display: flex;
}

.intro {
    cursor: pointer;
    color: #54a5be;
    text-align: center;
    font-feature-settings: 'rclt' off;
    text-overflow: ellipsis;
    font-family: 'Segoe UI Variable';
    font-size: 12px;
    font-style: normal;
    font-weight: Normal;
    line-height: 16px;
}

.title-box {
    position: relative;
    padding: 0px 0px 0px 3px;
    box-sizing: border-box;
    // border-bottom: 1px solid #629EC8;
    // background: linear-gradient(90deg, #CFEDFA 0%, #e5e5e500 100%);

    &::after {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        width: 3px;
        height: 3px;
        display: inline-block;
        border-top: 1px solid rgba(0, 0, 0, 0.61);
        border-right: none;
        border-bottom: none;
        border-left: 1px solid rgba(0, 0, 0, 0.61);
    }
}

.line2 {
    width: 4px;
    height: 2px;
    margin-left: 9px;
    background-color: rgba(98, 158, 200, 1);
}

.line {
    height: 1px;
    width: calc(100% - 13px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.36);
    margin-top: 2px;
}

.icon {
    color: #000000e6;
    font-size: 20px;
}

.title-nav {
    position: relative;
    width: 256px;
    color: #000000e6;
    font-feature-settings: 'rclt' off;
    font-family: 'Segoe UI Variable';
    font-size: 14px;
    font-style: normal;
    font-weight: Bold;
    margin-left: 23px;
    line-height: 20px;
    min-height: 20px;

    &::before {
        content: '\e624';
        font-family: 'iconfont' !important;
        position: absolute;
        top: 1px;
        left: -25px;
        color: #000;
        font-size: 20px;
    }
}
</style>
