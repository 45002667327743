import router from '@/router'
import { useUserinfoStore } from '@/store/modules/userInfo'
import axios from 'axios'
import { ElMessage } from 'element-plus'
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.headers['pragma'] = 'no-cache'
//第二步:request实例添加请求与响应拦截器
axios.interceptors.request.use(
    async request => {
        const token = localStorage.getItem('token')
        if (token) {
            request.headers['token'] = token
        }
        return request
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)
// 返回值解构
axios.interceptors.response.use(
    response => {
        const data = response.data || {}

        const isJson = (response.headers['content-type'] || '').includes('json')
        if (isJson) {
            if (data.code === 2000) {
                return Promise.resolve({
                    data: data.data,
                    msg: data.msg,
                    code: data.code,
                })
            } else {
                ElMessage.error(data.msg)
                return Promise.reject(data.msg || '网络错误')
            }
        } else {
            return data
        }
    },

    err => {
        if (err.response) {
            switch (err.response.status) {
                case 401:
                    if (!useUserinfoStore().tokenExpiredNotified) {
                        // 弹出提示框或通知用户 token 已失效
                        ElMessage.error('登录失效，请重新登录')
                        useUserinfoStore().setTokenExpiredNotified(true)
                        // ElMessage.error({ message: err.response.data.msg })
                    }
                    localStorage.removeItem('token')
                    router.push({
                        path: '/login',
                    })

                    Promise.reject(err)
                    break
                case 403:
                    ElMessage.error({ message: err.response.data.msg })
                    Promise.reject(err)
                    break
                case 405:
                    Promise.reject(err)
                    ElMessage.error({ message: err.response.data.msg })
                    break
                default:
            }
        }
        const isCancel = axios.isCancel(err)
        if (isCancel) {
            return new Promise(() => {
                return
            })
        }
        return Promise.reject(err)
    }
)

export function post(url: string, data?: object, config?: object) {
    return axios.post(url, data, config)
}
