<template>
    <div class="nodata-box flex-mid-mid" :style="{ 'width': width, 'height': height }">
        <img src="@/assets/images/noData.png" class="nodata-imgs" alt="">
        <div class="nodata-desc">{{ props.noDataDesc }}</div>
    </div>
</template>
<script setup lang="ts">
const props = defineProps({
    noDataDesc: {
        type: String,
        default: '暂无数据'
    },
    width: {
        type: String,
        default: '324px'
    },
    height: {
        type: String,
        default: '200px'
    },

})
</script>
<style lang="scss" scoped>
.nodata-box{
    flex-direction: column;
}
.nodata-imgs {
    width: 64px;
    height: 40px;
}

.nodata-desc {
    margin-top: 8px;
    color: #00000040;
    text-align: center;
    font-family: "Segoe UI Variable";
    font-size: 14px;
    font-style: normal;
    font-weight: Normal;
}
</style>