


export default {
    install(Vue: any) {
      
      /**
       * 图表自适应
       * 指令用法：
       *  - 在需要图表上使用 v-resize = method , 如下：
       *    <div  v-resize="resize" >添加用户</div>
       *  - 当图表宽度或者高度发生变化触发并执行method方法
       */
      Vue.directive('resize', {
        
        bind(el: any, binding:any) {
          // el为绑定的元素，binding为绑定给指令的对象
          let width = '',
            height = '';
  
          function isResize() {
            const style = document.defaultView?.getComputedStyle(el);
            if (width !== style?.width || height !== style.height) {
              binding.value(); // 关键
            }
            width = style?.width || '';
            height = style?.height|| '';
          }
          el.__vueSetInterval__ = setInterval(isResize, 300);
        },
        unbind(el:any) {
          clearInterval(el.__vueSetInterval__);
        }
      });
    }
  };
  